import React from "react";
import Count from "../components/Count";

export default function Statistics() {
  return (
    <div>
      <div className="w-full h-32 bg-white-curve"></div>
      <div className="bg-white">
        <div className="w-4/5 md:w-7/12 mx-auto pt-24 flex justify-center flex-col">
          <h2 className="text-center">
            Dentsu Marketing Cloud is deployed in multiple markets across the globe.
          </h2>
          <div className="flex flex-col md:flex-row justify-center items-center w-full md:mt-16">
            <div className="text-center md:w-1/3">
              <h1 className="grad-green-blue">
                <Count
                  width={"w-52 mx-auto "}
                  start={1450}
                  end={2000}
                  suffix="+"
                />
              </h1>
              <p className="text-xl uppercase">Clients</p>
            </div>
            <div className="text-center md:w-1/3">
              <h1 className="grad-green-blue">
                <Count width={"w-24 mx-auto "} end={33} suffix="%" />
              </h1>
              <p className="text-xl uppercase">Increased Efficiency</p>
            </div>
            <div className="text-center md:w-1/3">
              <h1 className="grad-green-blue">
                <Count width={"w-54 mx-auto "} end={5000} suffix="+" />
              </h1>
              <p className="text-xl uppercase">Campaigns Improved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
