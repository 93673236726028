export const testimonials = [
    {
        company: 'Nestle',
        logo: 'nestle.png',
        userName: 'Manager, Media Management Division, Marketing & Communications Headquarters',
        feedback: 'This is a story that become a popular topic of conversation in our company. As regards to a phrase "coffee maker". We tend to call baristas "coffee machines" in marketing. However, in real market, the term "coffee maker" was more way more common. At that time, when I checked the Planner, it already appeared that the keyword "coffee maker" with high importance. I felt that it was capturing user data well. I feel grateful to be informed something that we tend to miss'
    },
    {
        company: 'Hemmas',
        logo: 'hemmas.png',
        userName: '- Kazi Arif Zaman, Country head',
        feedback: 'I wanted to extend my gratitude to D’reach/MediaAxis on the recent brand awareness campaign that we ran on digital platform using DMC. The reach has been phenomenal at 3.8 mlln which was achieved at a much lower CPC and the CTR was significantly higher than planned. The TG targeting was effective and analytics used in this seems to have worked as I have received calls from industry peers on this. I express my gratitude to the digital team members of D’reach and DMC and hope to see more magic in the coming years.'
    },
    {
        company: 'PT Bank Amar Indonesia, Tbk',
        logo: 'ab.png',
        userName: 'Khalid Rahiel, Chief Marketing Officer',
        feedback: 'Using Dentsu Marketing Cloud we were able to identify unique interests and preferences for each of our target personas. Taking a step further - it recommended a granular targeting plan for our awareness campaign based on these insights, which provided a tremendous head start. Dentsu Marketing Cloud helped in bringing marketing efficiency by not just marrying tech & data, but making it actionable, reducing our costs while delivering more impressions & visits for our campaign.'
    },
    {
        company: 'Guardian GNC Healthcare',
        logo: 'GNC-Logo.png',
        userName: 'Shadab Umar Khan, Chief Executive Officer',
        feedback: 'we at GNC were excited to use Dentsu Marketing Cloud, an automation tool that serves as a unified advertising and analytics platform. It helped us to build an audience with the relevant cohorts through AI and machine learning. We are looking forward to exploring more such tools zthat aid in the growth of our digital campaigns.'
    }
]