import React from 'react'
import { testimonials } from '../data/testimonials'
import Slider from "react-slick";
import Image from '../components/Image';

export default function Testimonials() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        centerPadding: '10px'
      };
    return (
        <div className=' md:mt-32 md:mb-8 md:w-2/3 mx-auto text-center'>
            <h2 className='my-10 w-4/5 mx-auto'>What our clients say about us</h2>
            <Slider {...settings}>
                {testimonials.map(data=>{
                    return <div key={data.company}>
                        <div className='flex flex-col md:flex-row items-center'>
                        <div className='md:w-1/4 p-10'>
                            <div className='rounded '>
                                <Image src={data.logo} alt=''/>
                            </div>
                        </div>
                        <div className='md:w-3/4 md:text-left md:text-xl p-6'>
                            {data.feedback}
                            <h6>{data.userName}</h6>
                        </div>
                    </div>
                    </div>
                })}
            </Slider>
        </div>
    )
}
