import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

export default function Hero() {
    return (
            <div className='w-100 lg:w-3/4 mx-auto py-6 my-8 text-center'>
                <h1 className='my-5 w-4/5 mx-auto'>Delivering <div className='grad-purple-blue capitalize'>data led intelligence</div> to solve for marketing effectiveness</h1>
                <p className='my-5 w-2/3 mx-auto'>
                    A battle-tested platform enabling you to bring the sophistication of technology to optimize your marketing goals by discovering and deciphering audiences, planning better and optimising efficiently across your audience!
                </p>
                <StaticImage src='../images/hero.png' width={800} alt="" />
            </div>
    )
}
