import React from 'react'
import Button from '../components/Button'

export default function Subscribe() {
    return (
        <div className='bg-grad-black mx-10 md:mx-auto md:w-1/2 md:my-32  my-20 rounded-xl text-center p-10'>
            <h2 className='text-white'>Be a part of our story</h2>
            <div className='w-full flex justify-center'>
            <a href="mailto:dmcsupport@dentsu.com?subject=Demo%20Request&body=Hi,%20I%20would%20like%20to%20request%20a%20demo%20for%20DMC."><Button>Talk to Us</Button></a>
            </div>
            {/* <input placeholder='Enter your email'/>  */}
        </div>
    )
}
