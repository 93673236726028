import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Marquee from "react-fast-marquee";
// Note: You can change "images" to whatever you'd like.

const OurClients = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "clients" } }) {
            nodes {
                id
                name
                childImageSharp {
                  gatsbyImageData(width: 100, layout: FIXED)
                }
            }
        }
      }
    `}
    render={(data) => {
      const images = data.allFile.nodes.map((n) =>{
        return {
            name: n.name,
            src: n.childImageSharp.gatsbyImageData,
            id: n.id
        }
      }
      );
      if (!images.length) {
        return null;
      }
      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      // console.log(images)
      return (
        <>
        <Marquee direction="left" speed={100} gradient={false} className=" my-20 bg-secondary/10 shadow-inner py-3 opacity-75">
            
            {
                images.map(i=><div key={i.id} className="w-44 flex grayscale opacity-50 hover:opacity-100 hover:grayscale-0 items-center px-5"><GatsbyImage image={getImage(i.src)} alt={i.name} /></div>)
            }
            
        </Marquee>
        {/* <div className="flex flex-wrap justify-center ">
        {
                  images.map(i=><div key={i.id} className="w-44 flex justify-center items-center px-5"><GatsbyImage image={getImage(i.src)} alt={i.name} /></div>)
              }
        </div> */}
        </>
      );
    }}
  />
);

export default OurClients;
