import React from "react";
import Button from "../components/Button";
import { StaticQuery, graphql } from "gatsby";
import Image from '../components/Image'


export default function ProductCycle() {
  return (
    <div className="random-svg-bg py-28">
      <div className="w-3/4 md:w-2/3 mx-auto px-10 text-center text-white">
        <h3>
          <span className="grad-green-blue">Campaign Journey</span>
        </h3>
        <h3 className="inline-flex items-center flex-wrap justify-center">Insights&nbsp;<Image className='mx-2' alt='' src='next.png' width={20} />Planning&nbsp;<Image className='mx-2' alt='' src='next.png' width={20} /> Activation&nbsp;<Image className='mx-2' alt='' src='next.png' width={20} /> Optimisation</h3>
        <div>
          <p className="mb-3 w-3/4 mx-auto">
            Your campaign journey with DMC spans mainly across 4 chronological
            phases, each of which plays a crucial role is determining the
            success of your marketing campaign. At DMC, we have designed highly
            specialized tools to help you perform tasks required during your
            marketing journey. Let us now take an in-depth view into of what
            your marketing campaign would ideally look like and the tools you
            would use to successfully meet your objectives{" "}
          </p>
          <div className="w-full flex justify-center">
          <a target="_blank" rel="noopener noreferrer" href="https://blog.dentsumarketing.cloud/"><Button type="light">Read more</Button></a>
            
          </div>
        </div>
      </div>
    </div>
  );
}

