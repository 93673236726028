import React from 'react'
import Image from '../components/Image'

export default function UserCenteric() {
    return (
       <div className='bg-white-curve-sm'>
            <div className='md:w-2/3 mx-auto text-center py-20 md:pt-72 md:pb-24 flex flex-col justify-center'>
            {/* <h2 className='my-14 capitalize'>Self serve, user driven with full customer support</h2> */}
            <div className='flex justify-between md:flex-row flex-col mt-20'>
                <div className='md:w-1/3'>
                    <Image alt='' src='002-dashboard.png' width={100} />
                    <p className='my-5 text-2xl '>All in one Dashboard</p>
                </div>
                <div className='md:w-1/3'>
                    <Image alt='' src='001-social-care.png' width={100} />
                    <p className='my-5 text-2xl '>Help and Support</p>
                </div>
                <div className='md:w-1/3'>
                    <Image alt='' src='technical-support.png' width={100} />
                    <p className='my-5 text-2xl '>Configurations & Preferences</p>
                </div>
            </div>
        </div>
       </div>
    )
}
