import React from 'react'
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {features} from '../data/features'
import Image from '../components/Image';

function KeyFeatureItem({data}) {
    return (
        <div className={`flex flex-col ${data.row==='1' ? "md:flex-row ":"md:flex-row-reverse"} w-11/12 md:w-3/4 mx-auto my-20 items-center justify-center`}>
            <div className={`${data.row==='1' ? "md:w-1/3 md:mr-8":"md:w-1/3 md:ml-8"} md:w-1/3 md:mr-8`}>
                <Image alt='' src={data.mainImage}  />
            </div>
            <div className='w-4/5 md:w-1/3 text-center md:text-left '>
                <div className=''>
                    <h3>{data.title}</h3>
                    <p>
                        {data.mainContent}
                    </p>
                    <div className='flex justify-evenly flex-col flex-wrap my-5 w-full'>
                        {
                            data.subFeatures.map(item=>{
                                return <div className='flex items-center p-3' key={item.title.split(' ').join('_')}>
                                <Image alt='' src={item.image} width={40} />
                                <p className='ml-4'>{item.title}</p>
                            </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function KeyFeatures(){
    return (
        <div>
            {
                features.map(item=>{
                    return <KeyFeatureItem key={item.title.split(' ').join('_')} data={item}/>
                })
            }
        </div>
    )
}


