export const features = [
    {
        title: 'Begin your Insights journey',
        row: '1', 
        mainImage: 'insights.png', 
        mainContent: 'Gain holistic audience insights from a multitude of data sources to help your brands find the right audience.', 
        subFeatures : [
            {
                title: 'Insightful Audience Preferences',
                image: '001-behavior.png'
            },
            {
                title: 'Content Consumption Data',
                image: '002-content.png'
            },
            {
                title: 'Clear Demographics & Behaviour',
                image: '003-demographic.png'
            }
        ]
    },
    {
        title: 'Create a Plan',
        row: '0', 
        mainImage: 'planning.png', 
        mainContent: 'Leverage DMC\'s proprietary algorithm to create an optimal plan based on your budget and most effective audiences.', 
        subFeatures : [
            {
                title: 'Effective Reach Estimate',
                image: '001-social-ad-reach.png'
            },
            {
                title: 'Optimal Budget Allocation',
                image: '002-budget.png'
            },
            {
                title: 'Ad set Targeting Breakdown',
                image: '003-targeting.png'
            }
        ]
    },
    {
        title: 'Activate your Plan',
        row: '1', 
        mainImage: 'activation.png', 
        mainContent: 'Create actionable campaign plans that can be activated within and outside of walled garden ecosystems', 
        subFeatures : [
            {
                title: 'Effortless Plan Activation',
                image: '001-work-time.png'
            },
            {
                title: 'Actionable Campaign Creation',
                image: '002-marketing.png'
            },
            {
                title: 'Activate Audiences on FB ecosystem & Programmatic channels',
                image: '003-social-media.png'
            }
        ]
    },
    {
        title: 'Optimize your campaigns',
        row: '0', 
        mainImage: 'optimize.png', 
        mainContent: 'Benefit from rich and unique interests and behaviour based reports that can help you maximize performance through bids, targeting and creatives', 
        subFeatures : [
            {
                title: 'Modify interest targeting',
                image: '001-competence.png'
            },
            {
                title: 'Identify performing creatives',
                image: '002-performance.png'
            },
            {
                title: 'Adjust bids ',
                image: '003-bid.png'
            }
        ]
    }
]




