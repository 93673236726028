import React from 'react'
import DataPartners from '../containers/DataPartners'
import Footer from '../containers/Footer'
import Header from '../containers/Header'
import Hero from '../containers/Hero'
import KeyFeatures from '../containers/KeyFeatures'
import OurClients from '../containers/OurClients'
import ProductCycle from '../containers/ProductCycle'
import Statistics from '../containers/Statistics'
import UserCenteric from '../containers/UserCenteric'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Testimonials from '../containers/Testimonials'
import Subscribe from '../containers/Subscribe'
import Layout from '../containers/Layout'

export default function index() {
  return (
   <Layout title="DMC">
      <Hero/>
      <ProductCycle/>
      <KeyFeatures/>
      <Statistics/>
      {/* <DataPartners/> */}
      <UserCenteric/>
      <OurClients/>
      <Testimonials/>
      <Subscribe/>
   </Layout>
  )
}
