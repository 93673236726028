import React, { useRef, useState } from "react";
import CountUp from 'react-countup';
import useOnScreen from "../hooks/onScreen";

export default function Count({start=0, end, width, suffix}) {
  const ref = useRef()
  const isVisible = useOnScreen(ref)
  const [CounterFInished, setCounterFInished] = useState(false)
  return (
      <span ref={ref} className={`${width}`}>
        <CountUp
        start={start}
        end={end}
        duration={1}
        // delay={0}
        separator=" "
        decimals={0}
        decimal=","
        easingFn={`easeOutExpo`}
        // prefix="EUR "
        suffix={suffix}
        // onEnd={() => console.log("Ended! 👏")}
        // onStart={() => console.log("Started! 💨")}
      >
        {({ countUpRef, start }) => {
          if(isVisible && !CounterFInished){
            start()
            setCounterFInished(true)
          }
          return <span ref={countUpRef} />
        }}
      </CountUp>
      </span>
  );
}
